<template>
  <div>
    <!-- List Box Import Excel Result-->
    <b-modal
      id="error-list-modal"
      title="ErrorList"
      cancel-disabled
      ok-only
      ok-title="Close"
      centered
    >
      <DataTable :value="errorList" :scrollable="true" scrollHeight="200px">
        <Column field="row" header="Row" headerStyle="width: 80px"></Column>

        <Column field="error" header="Error"></Column>
      </DataTable>
    </b-modal>

    <!--Add User Modal -->
    <b-modal
      id="add-user-modal"
      title="ユーザー追加"
      size="lg"
      @ok="handleAddUser"
      centered
    >
      <ValidationObserver ref="formadd">
        <b-form>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="input-group-2"
                label="メールアドレス(*):"
                label-for="email-input-add"
              >
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="email-input-add"
                    v-model="form.email"
                    placeholder="メールアドレス"
                    required
                  />
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="input-group-1"
                label="氏名(*):"
                label-for="user-name-add"
              >
                <!-- description="We'll never share your email with anyone else." -->
                <ValidationProvider
                  name="fullname"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="user-name-add"
                    type="text"
                    v-model="form.username"
                    placeholder="フルネーム"
                    required
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-phone"
                label="電話番号(*):"
                label-for="input-phone-add"
              >
                <ValidationProvider
                  name="phone"
                  rules="required|phonenumber"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-phone-add"
                    placeholder="電話番号"
                    v-model="form.phone"
                    required
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-company"
                label="会社名:"
                label-for="input-company-add"
              >
                <b-form-input
                  id="input-company-add"
                  v-model="form.company"
                  placeholder="会社名"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-department"
                label="部署:"
                label-for="input-department-add"
              >
                <b-form-input
                  id="input-department-add"
                  v-model="form.department"
                  placeholder="部署"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-position"
                label="役職:"
                label-for="input-position-add"
              >
                <b-form-input
                  id="input-position-add"
                  v-model="form.position"
                  placeholder="役職"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group label="住所:" label-for="input-address-add">
                <b-form-input
                  id="input-address-add"
                  v-model="form.address"
                  placeholder="住所"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-password"
                label="パスワード(*):"
                label-for="input-password-add"
              >
                <ValidationProvider
                  name="password"
                  rules="passwordRule"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password-add"
                    type="password"
                    v-model="form.password"
                    placeholder="パスワード"
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-role"
                label="ロール:"
                label-for="input-role"
              >
                <b-form-select :options="roles" v-model="selectAddUserRole">
                </b-form-select>
              </b-form-group>
            </b-col>
            
          </b-row>
        </b-form>
      </ValidationObserver>
      <template #modal-cancel>
        <b-button class="p-0">キャンセル</b-button>
      </template>
    </b-modal>
    <!--Edit User Modal -->
    <b-modal
      id="edit-user-modal"
      title="ユーザー編集"
      @ok="handleEditUser"
      size="lg"
      centered
    >
      <ValidationObserver ref="formedit">
        <b-form>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="input-group-2"
                label="メールアドレス:"
                label-for="email-input"
              >
                <b-form-input
                  id="email-input"
                  v-model="edit.email"
                  placeholder="Email"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="input-group-1"
                label="氏名:"
                label-for="user-name"
              >
                <!-- description="We'll never share your email with anyone else." -->
                <ValidationProvider
                  name="fullname"
                  rules="required"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="user-name"
                    type="text"
                    v-model="edit.username"
                    placeholder="氏名"
                    required
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-phone"
                label="電話番号:"
                label-for="input-phone"
              >
                <ValidationProvider
                  name="phone"
                  rules="required|phonenumber"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-phone"
                    placeholder="電話番号"
                    v-model="edit.phone"
                    required
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-company"
                label="会社名:"
                label-for="input-company"
              >
                <b-form-input
                  id="input-company"
                  v-model="edit.company"
                  placeholder="会社名"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-department"
                label="部署:"
                label-for="input-department"
              >
                <b-form-input
                  id="input-department"
                  v-model="edit.department"
                  placeholder="部署"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-position"
                label="役職:"
                label-for="input-position"
              >
                <b-form-input
                  id="input-position"
                  v-model="edit.position"
                  placeholder="役職"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-address"
                label="住所:"
                label-for="input-address"
              >
                <b-form-input
                  id="input-address"
                  v-model="edit.address"
                  placeholder="住所"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-password"
                label="パスワード:"
                label-for="input-password"
              >
                <ValidationProvider
                  name="password"
                  rules="passwordRule"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    type="password"
                    v-model="edit.password"
                    placeholder="パスワードを変更しない場合は空白にしてください。"
                  ></b-form-input>
                  <span class="input-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group
                id="label-role"
                label="ロール:"
                label-for="input-role"
              >
                <b-form-select :options="roles" v-model="selectEditUserRole">
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" class="mb-md-0 mb-2">
              <b-form-group id="label-active" label="ステータス:">
                <template>
                  <el-radio v-model="edit.isActive" label="1"
                    >解除</el-radio
                  >
                  <el-radio v-model="edit.isActive" label="0"
                    >ロック</el-radio
                  >
                </template>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      <template #modal-cancel>
        <b-button class="p-0">キャンセル</b-button>
      </template>
    </b-modal>
    <!--Import Excel Modal -->
    <b-modal
      id="excel-modal"
      title="エクセルからユーザーリストインポート"
      @ok="handleImportExcel"
      cancel-title="キャンセル"
      centered
    >
      <b-form-file
        locale="ja"
        browse-text="ブラウズ"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        v-model="fileExcel"
        placeholder="ユーザーリストのエクセルファイルを選択してください。"
        ref="fileinput"
        class="mb-2"
      ></b-form-file>
    </b-modal>

    <user-filter v-on:clickSearch="handleSearch" :filter="search" />
    <b-card no-body class="mb-0 p-1">
      <!-- <h1>List User</h1> -->
      <div class="d-flex justify-content-between mb-1">
        <b-button variant="primary" @click="addUser">ユーザー追加</b-button>
        <b-button variant="primary" @click="openImportExcelModal"
          >エクセルインポート</b-button
        >
      </div>
      <b-table
        show-empty
        :sort-by.sync="sortBy"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        :busy="isBusy"
        :responsive="true"
        outlined
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>読み込み中...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(isActive)="data">
          <b-form-checkbox
            v-model="data.item.isActive"
            name="check-button"
            @change="(e) => handleChangeIsActive(e, data)"
            switch
          >
            {{ data.item.isActive ? "解除" : "ロック" }}
          </b-form-checkbox>
        </template>

        <template #cell(isSendEmail)="data">
          <b-form-checkbox
            v-model="data.item.isSendEmail"
            name="check-button"
            @change="(e) => handleChangeIsSendEmail(e, data)"
            switch
          >
            {{ data.item.isSendEmail ? "する" : "しない" }}
          </b-form-checkbox>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="openEditUser(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">編集</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">削除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BForm,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BSpinner,
  BFormCheckbox,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";

import Checkbox from "primevue/checkbox";
import UserFilter from "./UserFilter.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { AwardIcon } from "vue-feather-icons";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
extend("required", {
  ...required,
  message: "この項目は必須です。",
});

extend("passwordlength", (value) => {
  if (value.length >= 8) return true;

  return "パスワードは8文字以上である必要があります。";
});

extend("phonenumber", (value) => {
  let isPhoneValid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
    value
  );
  if (isPhoneValid) return true;

  return "有効な電話番号を入力して下さい。";
});

extend("passwordRule", (value) => {
  let isPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g.test(
    value
  );
  if (isPassword) return true;

  return "パスワードには次の文字種４種類すべてを含めてください。英字大文字、英字小文字、数字、記号";
});

extend("email", {
  ...email,
  message: "有効なメールアドレスを入力してください。",
});

export default {
  data() {
    return {
      errorList: [],
      windowWidth: window.innerWidth,
      fileExcel: null,
      sortBy: "email",
      selectAddUserRole: null,
      selectEditUserRole: null,
      roles: [],
      items: [],
      isMounted: false,
      isAdd: true,
      search: {
        name: "",
        email: "",
        phone: "",
        company: "",
        department: "",
        address: "",
        position: "",
        start: "",
        end: "",
        isActive: "All",
      },
      form: {
        username: "",
        email: "",
        company: "",
        phone: "",
        password: "",
        department: "",
        position: "",
        address: "",
      },
      edit: {
        id: "",
        username: "",
        email: "",
        company: "",
        phone: "",
        password: "",
        department: "",
        position: "",
        address: "",
        isActive: "0",
      },
      fields: [
        {
          key: "index",
          label: "No.",
          // thStyle: { width: "40px", padding: "0.72rem 1rem 0.72rem" },
          thStyle: { width: "40px" },
        },
             {
          key: "code",
          label: " 納品番号",
          // thStyle: { width: "40px", padding: "0.72rem 1rem 0.72rem" },
          thStyle: { width: "40px" },
        },
        {
          key: "fullName",
          label: "氏名",
          sortable: true,
          //  thStyle: {  padding: "0.72rem 1rem 0.72rem", "min-width": "200px"  },
          thStyle: { "min-width": "180px" },
        },
        {
          key: "email",
          label: "メールアドレス",
          sortable: true,
          //  thStyle: {  padding: "0.72rem 1rem 0.72rem", "min-width": "200px"  },
          thStyle: { "min-width": "180px" },
        },
        {
          key: "company",
          label: "会社名",
          sortable: true,
          //  thStyle: {  padding: "0.72rem 1rem 0.72rem", "min-width": "200px"  },
          thStyle: { "min-width": "180px" },
        },
        // {
        //   key: "department",
        //   label: "部署",
        //              thStyle: {  padding: "0.72rem 0.72rem", "min-width": "200px"  },
        // },
        // {
        //   key: "position",
        //   label: "役職",
        //              thStyle: {  padding: "0.72rem 0.72rem", "min-width": "200px"  },
        // },
        // {
        //   key: "address",
        //   label: "住所",
        //    thStyle: {  padding: "0.72rem 0.72rem",  "min-width": "200px" },
        //    tdClass: 'addressClass'
        // },
        {
          key: "phone",
          label: "電話番号",
          // thStyle: { width: "140px", padding: "0.72rem 1rem 0.72rem"  },
          thStyle: { width: "140px" },
        },
        {
          key: "role",
          label: "ロール",
          // thStyle: { width: "140px", padding: "0.72rem 1rem 0.72rem"  },
          thStyle: { "min-width": "120px" },
          // thStyle: { width: '140px' },
        },
        {
          key: "createAt",
          label: "作成日付",
          // thStyle: { width: "140px", padding: "0.72rem 1rem 0.72rem" },
          thStyle: { width: "140px" },
          sortable: true,
        },
        {
          key: "isActive",
          label: "ステータス",
          thStyle: { padding: "0.72rem 0.72rem", "min-width": "150px" },
          // thStyle: { width: "150px",padding: "0.72rem 1rem 0.72rem" },
          // thStyle: { width: '170px' },
        },
        {
          key: "isSendEmail",
          label: "通知メール送信",
          thStyle: { padding: "0.72rem 0.72rem", "min-width": "150px" },
          // thStyle: { width: "150px",padding: "0.72rem 1rem 0.72rem" },
          // thStyle: { width: '170px' },
        },
        {
          key: "action",
          label: "アクション",
          // thStyle: { width: "120px", padding: "0.72rem 1rem 0.72rem" },
          thStyle: { width: "120px" },
        },
      ],
      currentPage: 1,
      totalUsers: 1,
      perPage: 10,
      totalItems: 1,
      filter: "",
      currentUserId: null,
      queryString: "",
      isBusy: true,
    };
  },
  components: {
    DataTable,
    Column,
    BForm,
    BFormFile,
    BFormSelect,
    BFormCheckbox,
    Checkbox,
    BButton,
    BTable,
    BPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    UserFilter,
  },
  created() {
    // window.addEventListener("resize", this.handleResize);
    // console.log(this.fields)
  },
  destroyed() {
    // window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setWidthColumns() {},
    handleResize(e) {
      this.windowWidth = window.innerWidth;
    },
    async handleImportExcel() {
      if (this.$refs.fileinput.files.length == 1) {
        try {
          var bodyFormData = new FormData();
          bodyFormData.append("file", this.$refs.fileinput.files[0]);
          let res = await axios({
            url: `CustomUser/import-users`,
            method: "POST",
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          if (res.data.succeeded) {
            let okCount = res.data.data.okCount;
            // if (okCount > 0)
            this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Success",
                  text: `ユーザーを${okCount}件インポートできました。`,
                  variant: "success",
                },
              });

            if (res.data.data.errorRows.length > 0) {
              this.errorList = res.data.data.errorRows;
              this.$bvModal.show("error-list-modal");
            }
            this.$bvModal.hide("modal-import");
            await this.getData();
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "失敗しました。",
                text: "インポートが失敗しました。",
                variant: "danger",
              },
            });
          }
        } catch {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "失敗しました。",
              text: "インポートが失敗しました。",
              variant: "danger",
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Warning",
            text: "エクセルファイルが選択されていません。",
            variant: "warning",
          },
        });
      }
    },
    openImportExcelModal() {
      this.fileExcel = null;
      this.$bvModal.show("excel-modal");
    },
    async handleChangeIsActive(e, data) {
      let payload = {
        id: data.item.id,
        name: data.item.fullName,
        phone: data.item.phone,
        company: data.item.company,
        isActive: data.item.isActive,
      };
      try {
        let res = await axios.put("CustomUser/edit-user", payload);
        if (res.data.succeeded) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "成功",
              text: `更新が完了しました。`,
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "失敗しました。",
              text: "更新が失敗しました。",
              variant: "danger",
            },
          });
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "更新が失敗しました。",
            variant: "danger",
          },
        });
      }
      this.isBusy = false;
    },

    async handleChangeIsSendEmail(e, data) {
      let payload = {
        id: data.item.id,
        name: data.item.fullName,
        phone: data.item.phone,
        company: data.item.company,
        isActive: data.item.isActive,
        isSendEmail: data.item.isSendEmail,
      };
      try {
        let res = await axios.put("CustomUser/edit-user", payload);
        if (res.data.succeeded) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "成功",
              text: `更新が完了しました。`,
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "失敗しました。",
              text: "更新が失敗しました。",
              variant: "danger",
            },
          });
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: "更新が失敗しました。",
            variant: "danger",
          },
        });
      }
      this.isBusy = false;
    },

    async handleSearch() {
      this.isBusy = true;
      await this.getData();
      this.isBusy = false;
    },
    objectToQueryString(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    },
    addUser() {
      this.$bvModal.show("add-user-modal");
    },
    openEditUser(dataRow) {
      let data = { ...dataRow };
      this.edit.id = data.item.id;
      this.edit.email = data.item.email;
      this.edit.company = data.item.company;
      this.edit.username = data.item.fullName;
      this.edit.phone = data.item.phone;
      this.edit.department = data.item.department;
      this.edit.position = data.item.position;
      this.edit.address = data.item.address;
      this.edit.password = "";
      this.edit.isActive = data.item.isActive ? "1" : "0";
      this.selectEditUserRole = data.item.roleId;
      this.$bvModal.show("edit-user-modal");
    },
    async handleAddUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      let isFormValid = await this.$refs.formadd.validate();
      if (!isFormValid) {
        return;
      }
      this.$bvModal.hide("add-user-modal");
      this.isBusy = true;
      let payload = {
        name: this.form.username,
        email: this.form.email,
        phone: this.form.phone,
        password: this.form.password,
        company: this.form.company,
        roleId: this.selectAddUserRole,
        position: this.form.position,
        department: this.form.department,
        address: this.form.address,
      };
      try {
        let res = await axios.post("CustomUser/register", payload);
        if (res.data.succeeded) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "成功",
              text: `ユーザーが追加できました。`,
              variant: "success",
            },
          });
          this.getData();
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Warning",
              text: res.data.message,
              variant: "danger",
            },
          });
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "失敗しました。",
            text: e.response.data.message,
            variant: "danger",
          },
        });
      }
      this.form.username = "";
      this.form.email = "";
      this.form.company = "";
      this.form.password = "";
      this.form.phone = "";
      this.form.position = "";
      this.form.department = "";
      this.form.address = "";

      this.isBusy = false;
    },
    async getData() {
      try {
        let activeFilter = "";
        if (this.search.isActive == "True") {
          activeFilter = true;
        }
        if (this.search.isActive == "False") {
          activeFilter = false;
        }

        let payload = { ...this.search };
        payload.isActive = activeFilter;

        let queryString = this.objectToQueryString(payload);

        let res = await axios.get(
          `CustomUser/?pageindex=${this.currentPage}&${queryString}`
        );
        this.items = res.data.data.items;
        this.totalUsers = res.data.data.totalCount;
      } catch {}
    },
    async handleFilter() {
      this.isBusy = true;
      await this.getData();
      this.isBusy = false;
    },
    async handleEditUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      let isFormValid = await this.$refs.formedit.validate();
      if (!isFormValid) {
        return;
      }
      this.$bvModal.hide("edit-user-modal");

      this.isBusy = true;
      let payload = {
        id: this.edit.id,
        name: this.edit.username,
        phone: this.edit.phone,
        password: this.edit.password,
        company: this.edit.company,
        isActive: this.edit.isActive == 1 ? true : false,
        roleId: this.selectEditUserRole,
        address: this.edit.address,
        department: this.edit.department,
        position: this.edit.position,
      };
      try {
        let res = await axios.put("CustomUser/edit-user", payload);
        if (res.data.succeeded) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Success",
              text: `更新が完了しました。`,
              variant: "success",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Failed",
              text: "更新が失敗しました。",
              variant: "danger",
            },
          });
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Failed",
            text: e.response.data.message,
            variant: "danger",
          },
        });
      }
      await this.getData();
      this.isBusy = false;
    },
    async getRoles() {
      let res = await axios.get("CustomUser/all-role");
      let roleData = res.data.data;
      if (roleData) {
        this.roles = roleData.map(function(item) {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
    },
    async confirmDelete(data) {
      this.$confirm("このユーザーを削除してもよろしいですか。", "警告", {
        confirmButtonText: "OK",
        cancelButtonText: "キャンセル",
        type: "warning",  
      })
        .then(() => {
          axios.delete(`users/?id=${data.item.id}`).then(async (response) => {
            if (response.data.succeeded) {
              await this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "成功",
                  text: `削除が完了しました。`,
                  variant: "success",
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "失敗しました。",
                  text: "削除が失敗しました。",
                  variant: "danger",
                },
              });
            }
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Canceled",
              text: "削除がキャンセルされました。",
              variant: "info",
              solid: true,
            },
          });
        });
    },
  },
  async mounted() {
    this.isBusy = true;
    await this.getData();
    this.isBusy = false;
    this.isMounted = true;
    this.getRoles();
  },
  watch: {
    currentPage: {
      handler: async function(value) {
        this.isBusy = true;
        await this.getData();
        this.isBusy = false;
      },
    },
  },
};
</script>

<style>
#btn-search {
  padding: 0.785rem 1.4rem !important;
  border-color: #ccc;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
#input-search {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.input-error {
  color: #f22435;
  display: block;
  margin-top: 5px;
  margin-left: 2px;
}
.addressClass {
  word-break: break-all;
}
.isActiveClass {
  width: 300px;
}
</style>
